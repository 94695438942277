<template>
  <div>
    <v-container v-if="quotations.length" class="mt-16">
      <v-tabs
        class="my-4 wrapper-tabs"
        hide-slider
        style="box-shadow: none !important"
      >
        <v-tab :href="`#quote`">Cotizaciones enviadas</v-tab>

        <v-tab-item value="quote">
          <pagination
            :pagination="pagination"
            @input="onInputPaginate"
            light
            :total-visible="5"
            class="w-100"
          />

          <v-card
            class="mx-4 my-4"
            v-for="(quote, index) in quotations"
            :key="index"
            style="box-shadow: none !important"
          >
            <v-card-title>
              <span class="text-subtitle-1 font-weight-semibold primary--text">
                {{ quote.project.name }}
              </span></v-card-title
            >
            <v-card-subtitle>
              <div class="">
                <v-icon small>{{ icons.mdiOfficeBuilding }}</v-icon>
                {{ quote.project.company.company }}
              </div>

              <div class="">
                <v-icon small>{{ icons.mdiMapMarker }}</v-icon>
                {{ quote.project.city_name }} {{ quote.project.location_name }}
              </div>
            </v-card-subtitle>

            <v-card-subtitle>
              <div class="">
                <span class="font-weight-semibold"
                  >Garantia del servicio o producto:
                </span>
                {{ quote.warranty }}
              </div>
            </v-card-subtitle>

            <v-card-text>
              <v-chip small>{{ quote.created_at }}</v-chip>
            </v-card-text>

            <v-divider class=""></v-divider>
            <v-card-actions class="d-flex justify-space-between align-center">
              <v-btn color="primary" rounded @click="onViewQuote(quote)">
                Ver Cotización
              </v-btn>
              <div class="d-flex gap-10">
                <IconViewed :value="quote.is_see_company" />
                <IconWin :value="quote.win" />
              </div>
            </v-card-actions>

            <v-divider class="my-4"></v-divider>
          </v-card>

          <pagination
            :pagination="pagination"
            @input="onInputPaginate"
            light
            :total-visible="5"
            class="w-100"
          />
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
} from "@vue/composition-api";
import IconWin from "@/components/icons/Win";
import IconViewed from "@/components/icons/Viewed";
import Pagination from "@/components/general/Pagination";

import api from "@/services";
import { useRouter } from "@core/utils";
import { mdiOfficeBuilding, mdiMapMarker, mdiPhone } from "@mdi/js";

export default {
  name: "WorkwerQuotePublish",
  components: {
    IconWin,
    IconViewed,
    Pagination,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { router } = useRouter();

    const quotations = ref([]);
    const pagination = ref(null);

    const onViewQuote = ({ id }) => {
      router.push({ name: "worker.quotations.show", params: { id } });
    };

    const onInputPaginate = (page) => {
      getQuotations(page);
    };

    const getQuotations = async (page = 1) => {
      try {
        const { data: response } = await api.getQuotations({
          params: {
            page,
          },
        });
        const { data, meta } = response;

        quotations.value = data;
        pagination.value = { ...meta };
      } catch (error) {}
    };

    onMounted(() => {
      getQuotations();
    });

    return {
      quotations,
      pagination,

      icons: {
        mdiOfficeBuilding,
        mdiMapMarker,
        mdiPhone,
      },

      onViewQuote,
      onInputPaginate,
    };
  },
};
</script>

<style></style>
