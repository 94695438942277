<template>
  <v-icon :color="colorIcon">
    {{ icons.mdiEyeCheck }}
  </v-icon>
</template>

<script>
import { computed } from "@vue/composition-api";
import { mdiEyeCheck } from "@mdi/js";

export default {
  props: {
    value: {
      default: Boolean,
    },
  },
  setup(props) {
    const { value } = props;
    const isVale = computed(() => !!value);
    const colorIcon = computed(() => (isVale.value ? "primary" : ""));
    return {
      colorIcon,

      icons: {
        mdiEyeCheck,
      },
    };
  },
};
</script>

<style></style>
